.discr__letter-spacing {
    font-family: "Manrope", sans-serif;
}

.container {
    color: #586290;
    font-size: 13px;
    line-height: 16px;
    margin: 0 auto;
    padding: 26px 0;
    width: 340px;
}

.section__title {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.5px;
    margin-bottom: 17px;
}

.mainHome__light-btn {
    display: block;
    text-align: center;
    line-height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #586290;
    background: none;
    width: 154px;
    height: 35px;
    border: 1.5px solid #586290;
    border-radius: 8px;
    cursor: pointer;
}

.mainReigistration {
    background-image: url(../../../images/registration.png);
    height: 106px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 28px;
}

.mainReigistration__btn {
    font-family: "Manrope", sans-serif;
    text-align: center;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    line-height: 52px;
    color: #ffffff;
    background: none;
    border: 1.5px solid #ffffff;
    border-radius: 3px;
    width: 345px;
    height: 52px;
    letter-spacing: 1.5px;
}

.recommendation {
    margin-bottom: 32px;
}

.recommendation-title-overlay {
    margin-bottom: 25px;
}

.recommendation-title {
    font-weight: bold;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 12px;
}

.recommendation-logo-overlay {
    display: flex;
    flex-wrap: wrap;
}

.logo-minonuk {
    width: 184px;
    height: 72px;
    margin-right: 17px;
}

.logo-imzo {
    width: 136px;
    height: 72px;
}

.logo-inped {
    width: 339px;
    height: 56px;
}

.system {
    margin-bottom: 52px;
}

/* .system::after {
    content: "";
    display: none;
} */
.system-description {
    margin-bottom: 32px;
}

.system-description span {
    font-weight: bold;
}

.system-img img {
    width: 340px;
    height: 237px;
}

.purpose {
    margin-bottom: 26px;
}

/* .purpose::after {
    content: "";
    display: none;
} */
.purpose-content-overlay {
    margin-bottom: 37px;
}

.purposeList {
    margin-left: 12px;
    counter-reset: purposeList-item 0;
}

.purposeList-item {
    margin-bottom: 9px;
    position: relative;
}

.purposeList-item:last-of-type {
    margin-bottom: 0;
}

.purposeList-item::before {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    position: absolute;
    left: -14px;
    counter-increment: purposeList-item 1;
    content: counter(purposeList-item, decimal);
}

.purposeList-item:first-of-type::before {
    left: -10px;
}

.purposeList-item:last-of-type::before {
    left: -16px;
}

.purposeList-item-title {
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 18px;
    line-height: 22px;
}

.purpose-img {
    margin: 0 auto;
    width: 280px;
    height: 210px;
}

.purpose-img img {
    width: 280px;
    height: 210px;
}

.journal {
    margin-bottom: 40px;
}

/* .journal::after {
    content: "";
    display: none;
} */
.journal-content-overlay {
    margin-bottom: 60px;
}

.journal-content-descr {
    margin-bottom: 14px;
}

.journal-img {
    margin: 0 auto;
    width: 290px;
    height: 193px;
}

.journal-img img {
    width: 290px;
    height: 193px;
}

.diary {
    margin-bottom: 42px;
}

/* .diary::after {
    content: "";
    display: none;
} */
.diary-content-overlay {
    margin-bottom: 50px;
}

.diary-content-discr {
    margin-bottom: 14px;
}

.diary-img {
    margin: 0 auto;
    width: 247px;
    height: 410px;
}

.diary-img img {
    width: 247px;
    height: 410px;
}

.schoolsUse-content-overlay {
    margin-bottom: 33px;
}

.schoolsUse-content-discr {
    margin-bottom: 25px;
}

.schoolsUse-counter-item-overlay {
    display: flex;
    justify-content: space-between;
    width: 310px;
}

.schoolsUse-counter-item-overlay:first-of-type {
    margin-bottom: 11px;
}

.schoolsUse-counter-item-overlay:last-of-type {
    margin-left: 30px;
}

.schoolsUse-counter-item {
    padding: 5px;
    width: 93px;
    height: 52px;
    border: 1.2px solid #586290;
    border-radius: 4px;
    text-align: center;
}

.schoolsUse-counter-item-number {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}

.schoolsUse-img {
    width: 338px;
    height: 226px;
}

.schoolsUse-img img {
    width: 338px;
    height: 226px;
}

.event-section h2 {
    text-align: center;
}

.event-section {
    margin-bottom: 10px;
}

.news {
    margin-bottom: 10px;
}

.news h2 {
    text-align: center;
}

.faq {
    margin-bottom: 20px;
}

.faq h2 {
    text-align: center;
}

.reviews h2 {
    text-align: center;
}

.reviews {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .container {
        width: 720px;
    }

    .section__title {
        text-align: center;
    }

    .logo-imzo {
        margin-right: 17px;
    }

    .recommendation {
        margin-bottom: 60px;
    }

    .system {
        display: flex;
        position: relative;
        margin-bottom: 100px;
    }

    .system-description {
        width: 380px;
        font-size: 18px;
        line-height: 20px;
    }

    .system-img {
        position: absolute;
        right: 0;
        top: -50px;
    }

    .purpose-content-overlay {
        width: 580px;
        margin: 0 auto;
    }

    .purposeList {
        width: 580px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .purposeList-item {
        width: 260px;
        margin-bottom: 16px;
    }

    .journal-content-overlay {
        margin: 0 auto;
        width: 500px;
        margin-bottom: 60px;
    }

    .diary-content-overlay {
        margin: 0 auto;
        width: 600px;
        margin-bottom: 50px;
    }

    .schoolsUse {
        position: relative;
        margin-bottom: 100px;
    }

    .schoolsUse-img {
        position: absolute;
        right: 20px;
        top: 100px;
    }

    .faq {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 960px;
    }

    .section__title {
        text-align: left;
    }

    .recommendation {
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
    }

    .recommendation-title-overlay {
        width: 450px;
        margin-right: 150px;
    }

    .system {
        position: relative;
        margin-bottom: 120px;
    }

    .system-description {
        width: 500px;
    }

    .system-img {
        width: 382px;
        height: 266px;
        top: -70px;
    }

    .system-img img {
        width: 382px;
        height: 266px;
    }

    .mainReigistration {
        background-image: url(../../../images/registration_992.png);
        height: 170px;
    }

    .mainReigistration__btn {
        font-size: 30px;
        line-height: 36px;
        width: 460px;
        height: 68px;
        line-height: 68px;
    }

    .purpose {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .purpose-content-overlay {
        width: 550px;
        order: 2;
    }

    .purpose-img {
        order: 1;
        position: relative;
        top: -15px;
    }

    .journal {
        position: relative;
        display: flex;
    }

    .journal-content-overlay {
        width: 400px;
        margin-bottom: 0;
    }

    .journal-img {
        position: relative;
        top: 35px;
    }

    .diary {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .diary-content-overlay {
        margin: 0;
        order: 2;
        width: 500px;
        position: relative;
        top: 50px;
    }

    .diary-img {
        margin: 0;
        order: 1;
    }

    .schoolsUse {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 0;
    }

    .schoolsUse-content-overlay {
        width: 500px;
    }

    .schoolsUse-img {
        position: relative;
        right: 0;
        top: 0;
        width: 439px;
        height: 294px;
    }

    .schoolsUse-img img {
        width: 439px;
        height: 294px;
    }

    .event-section {
        margin-bottom: 20px;
    }

    .news {
        margin-bottom: 20px;
    }

    .faq {
        margin-bottom: 40px;
    }

    .reviews {
        margin-bottom: 80px;
    }
}

@media (min-width: 1200px) {
    .discr__letter-spacing span {
        bottom: 10px;
    }

    .container {
        width: 1140px;
        font-size: 16px;
        line-height: 19px;
    }

    .section__title {
        font-size: 28px;
        line-height: 34px;
    }

    .mainHome__light-btn {
        font-size: 16px;
        line-height: 37px;
        width: 170px;
        height: 40px;
    }

    .mainReigistration {
        background-image: url(../../../images/registration_1920.webp);
        height: 200px;
    }

    .mainReigistration__btn {
        font-size: 36px;
        line-height: 44px;
        width: 560px;
        height: 85px;
        line-height: 85px;
        border: 2px solid #ffffff;
        border-radius: 4px;
    }

    .recommendation {
        margin-bottom: 50px;
    }

    .recommendation-title-overlay {
        width: 380px;
        margin-right: 0;
    }

    .recommendation-title {
        font-size: 28px;
        line-height: 34px;
    }

    .recommendation-logo-overlay {
        position: relative;
        top: 14px;
    }

    .system {
        margin-bottom: 170px;
    }

    .system-description {
        width: 500px;
        font-size: 22px;
        line-height: 28px;
    }

    .system-img {
        width: 545px;
        height: 380px;
        right: 0;
        top: -100px;
    }

    .system-img img {
        width: 545px;
        height: 380px;
    }

    .purpose {
        margin-bottom: 50px;
    }

    .purpose-img {
        width: 370px;
        height: 268px;
        top: 0;
    }

    .purpose-img img {
        width: 370px;
        height: 268px;
    }

    .purpose-content-overlay {
        width: 550px;
    }

    .purposeList-item {
        width: 240px;
        margin-bottom: 26px;
    }

    .purposeList-item-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 6px;
    }

    .purposeList-item::before {
        font-size: 20px;
        line-height: 24px;
        left: -16px;
    }

    .purposeList-item:first-of-type::before {
        left: -13px;
    }

    .purposeList-item:last-of-type::before {
        left: -19px;
    }

    .journal {
        justify-content: space-between;
        margin-bottom: 60px;
    }

    .journal-content-overlay {
        margin: 0;
        width: 600px;
    }

    .journal-content-descr {
        margin-bottom: 20px;
    }

    .journal-img {
        width: 435px;
        height: 290px;
        top: 0px;
    }

    .journal-img img {
        width: 435px;
        height: 290px;
    }

    .diary-content-overlay {
        width: 622px;
        top: 100px;
    }

    .diary-img {
        width: 333px;
        height: 534px;
    }

    .diary-img img {
        width: 333px;
        height: 534px;
    }

    .schoolsUse-img {
        width: 650px;
        height: 434px;
        top: -40px;
    }

    .schoolsUse-img img {
        width: 650px;
        height: 434px;
    }

    /* .system::after {
        display: block;
        position: absolute;
        width: 199px;
        height: 137px;
        background-image: url(../../../images/vector_system_sm.png);
        left: 404px;
        top: 196px;
    } */
    /* .purpose::after {
        display: block;
        position: absolute;
        width: 263px;
        height: 296px;
        background-image: url(../../../images/vector_purpose.png);
        left: 410px;
        top: 220px;
    } */
    /* .journal::after {
        display: block;
        position: absolute;
        width: 438px;
        height: 202px;
        background-image: url(../../../images/vector_journal.png);
        left: 260px;
        top: 280px;
    } */
    /* .diary::after {
        display: block;
        position: absolute;
        width: 342px;
        height: 180px;
        background-image: url(../../../images/vector_diary_sm.png);
        left: 246px;
        top: 430px;
    } */
    .event-section {
        margin-bottom: 30px;
    }

    .news {
        margin-bottom: 30px;
    }

    .faq {
        margin-bottom: 50px;
    }

    .reviews {
        margin-bottom: 90px;
    }
}

@media (min-width: 1920px) {
    .container {
        width: 1280px;
        font-size: 16px;
        line-height: 19px;
    }

    .mainReigistration {
        height: 229px;
        margin-bottom: 48px;
    }

    .section__title {
        font-size: 36px;
        line-height: 44px;
    }

    .mainHome__light-btn {
        font-size: 18px;
        line-height: 41px;
        width: 188px;
        height: 44px;
    }

    .recommendation {
        margin-bottom: 100px;
    }

    .recommendation-title-overlay {
        width: 500px;
    }

    .recommendation-title {
        font-size: 36px;
        line-height: 44px;
    }

    .recommendation-title span {
        display: block;
    }

    .recommendation-logo-overlay {
        top: 26px;
        flex-wrap: nowrap;
    }

    .logo-minonuk {
        width: 214px;
        height: 84px;
    }

    .logo-imzo {
        width: 140px;
        height: 84px;
    }

    .logo-inped {
        width: 400px;
        height: 73px;
    }

    .system {
        position: relative;
        margin-bottom: 210px;
    }

    .system-img {
        width: 713px;
        height: 497px;
    }

    .system-img img {
        width: 713px;
        height: 497px;
    }

    .system-description {
        width: 625px;
        font-size: 36px;
        line-height: 44px;
    }

    .system-img {
        position: absolute;
        right: 0;
        top: -100px;
    }

    .purpose {
        margin-bottom: 0;
    }

    .purpose-content-overlay {
        width: 580px;
    }

    .purposeList {
        margin-left: 18px;
        margin-top: 30px;
    }

    .purposeList-item {
        margin-bottom: 34px;
    }

    .purposeList-item-title {
        font-size: 24px;
        line-height: 29px;
    }

    .purposeList-item::before {
        font-size: 24px;
        line-height: 29px;
        left: -20px;
    }

    .purposeList-item:first-of-type::before {
        left: -15px;
    }

    .purposeList-item:last-of-type::before {
        left: -23px;
    }

    .purpose-img {
        width: 660px;
        height: 478px;
        top: -80px;
        left: -20px;
    }

    .purpose-img img {
        width: 660px;
        height: 478px;
    }

    .journal {
        margin-bottom: 20px;
    }

    .journal-content-overlay {
        width: 550px;
    }

    .journal-content-descr {
        margin-bottom: 25px;
    }

    .journal-img {
        margin: 0;
        width: 678px;
        height: 451px;
    }

    .journal-img img {
        width: 678px;
        height: 451px;
    }

    .diary-content-overlay {
        top: 170px;
    }

    .diary-img {
        width: 433px;
        height: 772px;
    }

    .diary-img img {
        width: 433px;
        height: 772px;
    }

    .schoolsUse-content-overlay {
        width: 630px;
    }

    .schoolsUse-counter-item-overlay {
        width: 600px;
    }

    .schoolsUse-counter-item-overlay:first-of-type {
        margin-bottom: 20px;
    }

    .schoolsUse-counter-item-overlay:last-of-type {
        margin-left: 100px;
    }

    .schoolsUse-counter-item {
        padding: 10px;
        width: 180px;
        height: 102px;
        border: 2.3px solid #586290;
        border-radius: 8.3px;
    }

    .schoolsUse-counter-item-number {
        font-size: 36px;
        line-height: 42px;
    }

    .schoolsUse-counter-item-discr {
        font-size: 24px;
        line-height: 28px;
    }

    /* .system::after {
        width: 167px;
        height: 148px;
        background-image: url(../../../images/vector_system.png);
        left: 460px;
        top: 342px;
    } */
    /* .purpose::after {
        width: 263px;
        height: 296px;
        left: 450px;
        top: 340px;
    } */
    /* .journal::after {
        width: 438px;
        height: 202px;
        left: 260px;
        top: 436px;
    } */
    /* .diary::after {
        width: 380px;
        height: 200px;
        background-image: url(../../../images/vector_diary.png);
        left: 310px;
        top: 640px;
    } */
    .event-section {
        margin-bottom: 60px;
    }

    .news {
        margin-bottom: 60px;
    }

    .faq {
        margin-bottom: 80px;
    }

    .reviews {
        margin-bottom: 120px;
    }
}

@media (max-width: 359px) {
    .container {
        width: 320px;
        padding: 10px 5px;
    }

    .schoolsUse-counter-item-overlay:last-of-type {
        margin-left: 0;
    }

    .schoolsUse-img {
        width: 308px;
        height: 210px;
    }

    .schoolsUse-img img {
        width: 308px;
        height: 210px;
    }

    .mainReigistration__btn {
        width: 300px;
        font-size: 19px;
    }

    .newsArrow {
        display: none;
    }

    .reviewsArrow {
        display: none;
    }

    .sliderNews {
        width: 310px;
    }

    .sliderReviews {
        width: 310px;
    }

    .schoolRegistration-form-input {
        width: 310px;
    }
}

.partners {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 0 60px 0;
}

.partners .left {
    width: 55%;
}

.partners .right {
    width: 45%;
}

.partners .item h2 {
    width: 100%;
    text-align: center;
    line-height: 1.2;
    margin: 0 0 38px 0;
}

.partners .item2 h2 {
    width: 100%;
    text-align: center;
    line-height: 1.2;
    margin: 0 0 42px 0;
}

.partners .mainTextConteiner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.partners .mainTextConteiner2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.partners .adressComponent {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #586290;
}

.partners .mainTextConteiner2 picture {
    margin-bottom: 25px;
}

@media (max-width: 768px) {
    .partners .left {
        width: 100%;
    }

    .partners .right {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .partners {
        margin: 0 0 5px 0;
    }

    .partners .item h2 {
        margin: 0 0 20px 0;
    }

    .partners .item2 h2 {
        margin: 0 0 20px 0;
    }


    .partners .mainTextConteiner {
        margin-bottom: 20px;
    }

    .partners .mainTextConteiner2 picture {
        margin-bottom: 20px;
    }

    .partners .mainTextConteiner2 .picture1 img {
        width: 138px;
        height: 72px;
    }

    .partners .mainTextConteiner2 .picture2 img {
        width: 217px;
        height: 41px;
    }

    .partners .mainTextConteiner2 .picture3 img {
        width: 243px;
        height: 32px;
    }
}